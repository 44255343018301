const AwsKeys = {
  userPoolId: "us-east-1_XzOGPLFVy",
  clientId: "3km8gpm8c0g4g950miklfspaev",
  responseType: "token",
  scope: "email+openid+phone",
  messagePath: "https://93vs7u2uc5.execute-api.us-east-1.amazonaws.com/v1",
  // messagePath: "https://api.simplelife.studio",
  awsCognitoURL: "https://simplelife.auth.us-east-1.amazoncognito.com",
  apiKey: "x6hl7PChB347ci437ZQIvDPRcZyQldz9CEqT14Yd"
}

export default AwsKeys;